import { Link } from "react-router-dom";
import {useState } from 'react';
import { useDispatch } from "react-redux";
import { ChangePassword } from "../../redux/actions/userActions";
import { useHistory } from 'react-router-dom';
import './main.css'; 
import { Circles } from "react-loader-spinner";
import Logo from '../../logo/Logo.svg';

const ChangePasswordPage = ({match}) =>{
  const history = useHistory();
  const token = match.params.token;
  const [submitted, setSubmitted] = useState(false);  
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    values: {},
    errors: {}
  });
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value
      },
      errors: {
        ...prevState.errors,
        [name]: validatePassword(name, value)
      }
    }));
  };


  const validatePassword = (name, password) => {
    if (!password) {
      if(name === 'password'){
        return `Password is required`;
      } else if(name === 'confirm_password'){
        return `Confirm Password is required`;
      }
            
    } else if (password.length < 6 || password.length > 15) {
      if(name === 'password'){
        return `Password must be between 6 and 15 characters`;
      } else if(name === 'confirm_password'){
        return `Confirm Password must be between 6 and 15 characters`;
      }
    } else {
      return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formState.errors.password && formState.errors.password != null ) {
      alert(formState.errors.password)
    } else if (formState.errors.confirm_password && formState.errors.confirm_password != null) {
      alert(formState.errors.confirm_password)
    } else {
      const { password, confirm_password } = formState.values;
      if(password === confirm_password){
        setSubmitted(true); 
        const { password } = formState.values;
        if (password && token) {
          dispatch(ChangePassword(password, token)).then((response)=>{
            if(response.success === 1){
              setLoading(false);
              navigateLogin();
            } else {
              setLoading(false)
            }
          });
        }
      } else {
        alert('Password and Confirm Password not matched.')
      }   
    }
  }

  const navigateLogin = () => {
		history.push('/login');
	};

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
 };

 const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmpasswordVisible);
 };
    
 return (
  <>
    <div className="container-scroller loginInner">
            <div className="container page-body-wrapper full-page-wrapper">
              <div className="content-wrapper d-flex align-items-center auth px-0 loginInner">
                <div className="row w-100 mx-0 loginBg">
                  <div className="col-lg-5 ml-auto">
                    <div className="auth-form-light text-left py-4 px-4 px-sm-5 loginBox">
                      <div className="brand-logo">
                        <img src={Logo} alt="logo" />
                      </div>
                      <h4 style={{paddingTop: "10px"}}>Create Your Password</h4>
                {/* <h6 className="fw-light">Signing up is easy. It only takes a few steps</h6> */}
                <form className="pt-3" onSubmit={handleSubmit} autoComplete="off">
                  <div className="form-group position-relative">
                  <label>Password <span className="required">*</span></label>
                    <input
                      type={passwordVisible ? 'text' : 'password'}
                      className={'form-control form-control-lg' + (submitted && !formState.values.password ? ' is-invalid' : '')}
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={formState.values.password || ''}
                    />
                    <i
                      onClick={togglePasswordVisibility}
                      className={passwordVisible ? 'fa fa-eye-slash position-absolute end-0 me-2' : 'fa fa-eye position-absolute end-0 me-2'}
                      style={{ top: '70%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                    ></i>
                    {submitted && !formState.values.password && (
                      <div className="inline-errormsg">Password is required</div>
                    )}
                    {formState.errors.password && (
                      <div className="inline-errormsg">{formState.errors.password}</div>
                    )}
                  </div>
                  <div className="form-group position-relative mt-3">
                  <label>Confirm Password<span className="required">*</span></label>
                    <input
                      type={confirmpasswordVisible ? 'text' : 'password'}
                      className={'form-control form-control-lg' + (submitted && !formState.values.confirm_password ? ' is-invalid' : '')}
                      name="confirm_password"
                      placeholder="Confirm Password"
                      onChange={handleChange}
                      value={formState.values.confirm_password || ''}
                    />
                    <i
                      onClick={toggleConfirmPasswordVisibility}
                      className={confirmpasswordVisible ? 'fa fa-eye-slash position-absolute end-0 me-2' : 'fa fa-eye position-absolute end-0 me-2'}
                      style={{ top: '70%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                    ></i>
                    {submitted && !formState.values.confirm_password && (
                      <div className="inline-errormsg">Confirm Password is required</div>
                    )}
                    {formState.errors.confirm_password && (
                      <div className="inline-errormsg">{formState.errors.confirm_password}</div>
                    )}
                  </div>
                  <div className="mt-3">
                    <button  className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn loginBtn" >
                      SUBMIT
                    </button>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    {/* Additional elements can be added here */}
                  </div>
                  <div className="text-center mt-4 fw-light">
                    <Link to="/login" className="createLink">Go Back to Login</Link>
                  </div>
                </form>
                    </div>
                  </div>
                </div>
              </div>          
            </div>        
          </div>
          {loading &&  <div className="fullscreen-loader">
          <Circles color="#F1B621" height={80} width={80} />
        </div>}
  </>
);
}

export default ChangePasswordPage;